import React from "react"
import "./button.scss"
import clsx from "clsx"

const Button = ({ children, className, Component = "button", ...rest }) => {
  return (
    <Component {...rest} className={clsx(className, "button")}>
      {children}
    </Component>
  )
}

export default Button
